<template>
  <el-dialog
    custom-class="ql-dialog "
    class="password-dialog"
    title="批量删除"
    :visible.sync="visible"
    width="589px"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    @close="handleCancel"
  >
    <div class="tips">
      <svg class="fz-18 icon" aria-hidden="true">
        <use xlink:href="#icon-icon_jinggao"></use>
      </svg>
      <p>确定删除以下学生吗？</p>
    </div>

    <el-form label-width="auto">
      <el-form-item label="删除学生名单">
        <div class="nameBox">
          <span v-for="name in studentNames" :key="name">{{ name }}</span>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click="handleCancel">取消</el-button>
      <el-button type="primary" size="small" @click="handleSave" :loading="loading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Dialog, Form, FormItem, Button } from 'element-ui';
import { batchDeleteStudent } from '@/api/basic';

export default {
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    students: Array,
  },

  computed: {
    studentNames() {
      return this.students.map((x) => x.studentName);
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    handleSave() {
      this.loading = true;

      batchDeleteStudent({
        userIdList: this.students.map((x) => x.studentId),
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: `删除成功`,
          });

          this.$emit('update:students', []);
          this.$emit('update:visible', false);
          this.$emit('success');
        })
        .catch(() => (this.loading = false));
    },

    handleCancel() {
      this.$emit('close');
    },
  },

  watch: {
    visible: {
      handler: function () {
        this.loading = false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  display: flex;
  align-items: center;
  padding: 0 17px;
  gap: 13px;
  height: 48px;
  background-color: #fffbf4;
  position: absolute;
  width: 100%;
  left: 0;
  top: 66px;
  color: #303333;
  line-height: normal;
}

.nameBox {
  background: #f8f8f8;
  @apply pl-15px pr-15px py-8px flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
  max-height: 222px;
  overflow: auto;

  span {
    @apply text-F1;
    line-height: 22px;
  }
}

.defaultTips {
  background-color: #f8f8f8;
  padding: 8px 12px;
  font-size: 12px;
  color: #7c8083;
  line-height: normal;
  margin-top: 8px;
}

.password-dialog {
  .tip {
    @apply text-12;
    line-height: 1.5;
    margin-top: 5px;
    color: #b9b9b9;
  }

  // 外层轨道
  ::-webkit-scrollbar-track {
    background-color: #f8f8f8;
  }

  ::v-deep .el-dialog__body {
    padding-top: 64px;
  }
}
</style>
