<template>
  <el-dialog
    custom-class="ql-dialog "
    :title="typeWord + '学生'"
    :visible.sync="dialogVisible"
    width="600px"
    @close="handleCancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item label="姓名" prop="name" size="small">
        <el-input v-model.trim="form.name" :style="{ width: '100%' }" placeholder="请输入学生姓名" />
      </el-form-item>

      <el-form-item label="学号" prop="idNumber" size="small">
        <el-input v-model.trim="form.idNumber" :style="{ width: '100%' }" placeholder="请输入学生学号" />
      </el-form-item>

      <el-form-item label="班级" prop="classIdList" size="small">
        <el-select v-model="form.classIdList" multiple :style="{ width: '100%' }" placeholder="请选择班级">
          <el-option v-for="item in classList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>

      <el-form-item label="性别" prop="sex" size="small">
        <el-radio-group v-model="form.sex">
          <el-radio :label="1" @click.native.prevent="handleRadio(1)">男</el-radio>
          <el-radio :label="2" @click.native.prevent="handleRadio(2)">女</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="optionType === DIALOG_TYPE_ADD" label="密码" prop="password" size="small">
        <el-input v-model.trim="form.password" :style="{ width: '100%' }" placeholder="请输入密码"></el-input>
        <div class="tips">
          <p class="tips-content">8-24位，至少包含数字、字母或特殊字符中两种方式</p>
          <p class="tips-content">
            若不填写密码，当学生有学号时，默认为qljy+学号后4位，不足4位则以0补位，例：qljy0001；当学生无学号时，默认为qljy0000
          </p>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" v-debounceClick="handleSave" :loading="loading" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Form, FormItem, Dialog, Button, Input, RadioGroup, Radio, Select, Option } from 'element-ui';
import { addStudent, editStudent, getClassList } from '@/api/basic';
import { checkQuote, checkPassword } from '@/utils/validate';
import { DIALOG_TYPE_ADD, DIALOG_TYPE_EDIT } from '../constance';
import { rsaEncryption } from '@/utils/common';

export default {
  name: 'StudentDialog',

  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },

    optionType: {
      type: Number,
      required: true,
      default: DIALOG_TYPE_ADD,
    },

    data: {
      type: Object,
    },
  },

  data() {
    const validateUserName = (rule, value, callback) => {
      if ((value || '').trim().length === 0) {
        callback(new Error('姓名不能为空'));
        return;
      }
      if (value.trim().length > 15) {
        callback(new Error('姓名过长'));
        return;
      }
      if (!checkQuote(value)) {
        callback(new Error('姓名不能包含特殊字符'));
        return;
      }
      callback();
    };
    const validateStudentNo = (rule, value, callback) => {
      if (!value || value === '') {
        callback();
        return;
      }
      if (value.trim().length > 25) {
        callback(new Error('学号过长'));
        return;
      }
      callback();
    };
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback();
        return;
      }
      const res = checkPassword(value);
      if (res !== true) {
        callback(new Error(res));
        return;
      }
      callback();
    };

    return {
      loading: false,
      dialogVisible: false,

      classList: [], // 班级列表

      form: {
        name: '',
        idNumber: '',
        classIdList: [],
        sex: '',
        password: '',
      },

      rules: {
        name: [
          { required: true, message: '姓名不能为空', trigger: ['blur', 'change'] },
          { validator: validateUserName, trigger: ['blur', 'change'] },
        ],
        idNumber: [{ validator: validateStudentNo, trigger: ['blur', 'change'] }],
        password: [{ validator: validatePassword, trigger: ['blur', 'change'] }],
      },
      DIALOG_TYPE_ADD,
      DIALOG_TYPE_EDIT,
    };
  },

  methods: {
    getClassList() {
      getClassList({
        pageSize: 9999,
        pageIndex: 1,
      }).then((res) => {
        if (res.status === 0) {
          this.classList = res.result.data.map((item) => ({
            label: `${item.gradeName}${item.className}`,
            value: item.classId,
          }));
        }
      });
    },

    handleRadio(val) {
      if (this.form.sex === val) {
        this.form.sex = '';
      } else {
        this.form.sex = val;
      }
    },

    handleSave() {
      this.loading = true;

      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            idNumber: this.form.idNumber,
            name: this.form.name,
            sex: this.form.sex,
            classIdList: this.form.classIdList,
          };

          if (this.optionType === DIALOG_TYPE_EDIT) {
            params.userId = this.form.studentId;
            editStudent(params)
              .then(() => {
                this.$message.success(`编辑学生成功`);
                this.$emit('success');
              })
              .catch(() => (this.loading = false));
          } else {
            params.password = rsaEncryption(this.form.password);
            addStudent(params)
              .then(() => {
                this.$message.success(`新增学生成功`);
                this.$emit('success');
              })
              .catch(() => (this.loading = false));
          }
        } else {
          this.loading = false;
        }
      });
    },

    handleCancel() {
      this.$emit('close');
    },
  },

  computed: {
    typeWord() {
      switch (this.optionType) {
        case DIALOG_TYPE_ADD:
          return '新增';
        case DIALOG_TYPE_EDIT:
          return '编辑';
        default:
          return '';
      }
    },
  },

  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());
          this.loading = false;

          this.getClassList();

          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });

          // 编辑
          if (this.optionType === DIALOG_TYPE_EDIT && this.data.studentId) {
            this.form = {
              ...this.data,
              name: this.data.studentName,
              classIdList: this.data.classIdList?.split(',') || [],
            };
          }
        }

        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  @apply T6 text-F2;
  margin-top: 8px;
  padding: 8px 12px;
  background-color: #f8f8f8;
  border-radius: 4px;
}
</style>
