<template>
  <el-dialog
    custom-class="ql-dialog "
    class="password-dialog"
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    width="589px"
    v-loading="isLoading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    @close="handleCancel"
  >
    <div class="tips">
      <svg class="fz-18 icon" aria-hidden="true">
        <use xlink:href="#icon-icon_jinggao"></use>
      </svg>
      <p>你确定修改以下学生密码为：{{ form.passwordType === 0 ? '默认密码' : '自定义密码' }}吗？</p>
    </div>

    <el-form :model="form" :rules="rules" ref="passwordForm" label-width="auto">
      <el-form-item label="学生名单">
        <div class="nameBox">
          <span v-for="name in studentNames" :key="name">{{ name }}</span>
        </div>
      </el-form-item>

      <el-form-item label="密码类型" prop="passwordType" size="small">
        <el-radio-group v-model="form.passwordType">
          <el-radio :label="TYPE_DEFAULT_PASSWORD">默认密码</el-radio>
          <el-radio :label="TYPE_NEW_PASSWORD">新密码</el-radio>
        </el-radio-group>

        <div v-if="form.passwordType === TYPE_DEFAULT_PASSWORD" class="defaultTips">
          <p>当学生有学号时，默认为qljy+学号后4位，不足4位则以0补位，例：qljy0001；当学生无学号时，默认为qljy0000</p>
        </div>
      </el-form-item>

      <el-form-item v-if="form.passwordType === TYPE_NEW_PASSWORD" label="新密码" prop="password" size="small">
        <el-input v-model.trim="form.password" :type="showPassword ? 'text' : 'password'" maxlength="24">
          <i
            slot="suffix"
            :class="
              'mr-10px iconfont cursor-pointer text-info ' + (showPassword ? 'icon-xianshi-zheng' : 'icon-yincang_bi')
            "
            @click="showPassword = !showPassword"
          />
        </el-input>
        <div class="tip">8-24位，至少包含数字、字母或特殊字符中两种方式</div>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click="handleCancel">取消</el-button>
      <el-button type="primary" size="small" v-debounceClick="handleSave" :loading="loading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveStudentPassword } from '@/api/basic';

import { Dialog, Form, FormItem, Button, Input, RadioGroup, Radio } from 'element-ui';

import { checkPassword } from '@/utils/validate';
import { PASSWORD_RESET_ONE, PASSWORD_RESET_BATCH, PASSWORD_RESET_ALL } from '../constance';
import { rsaEncryption } from '@/utils/common';

const TYPE_NEW_PASSWORD = 1;
const TYPE_DEFAULT_PASSWORD = 0;

export default {
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Input.name]: Input,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    optionType: Number,
    students: Array,
  },

  computed: {
    dialogTitle() {
      return this.optionType === PASSWORD_RESET_ONE ? '修改学生密码' : '重置学生密码';
    },

    studentNames() {
      if (this.optionType === PASSWORD_RESET_ALL) {
        return '全部学生';
      } else {
        return this.students.map((x) => x.studentName);
      }
    },
  },

  data() {
    const validatePassword = (rule, value, callback) => {
      const res = checkPassword(value);

      if (res !== true) {
        callback(new Error(res));
        return;
      }

      callback();
    };

    return {
      TYPE_NEW_PASSWORD,
      TYPE_DEFAULT_PASSWORD,
      PASSWORD_RESET_ONE,

      loading: false,
      isLoading: false,
      dialogVisible: false,
      form: {
        passwordType: TYPE_DEFAULT_PASSWORD,
        password: '',
      },
      rules: {
        password: [{ validator: validatePassword, trigger: 'blur' }],
      },

      showPassword: false,
    };
  },

  methods: {
    handleSave() {
      this.loading = true;

      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          switch (this.optionType) {
            case PASSWORD_RESET_ONE:
              this.saveStudentPassword();
              break;
            case PASSWORD_RESET_BATCH:
              this.batchResetStudentPassword();
              break;
            case PASSWORD_RESET_ALL:
              this.resetStudentPassword();
              break;
            default:
              break;
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },

    handleCancel() {
      this.$emit('close');
    },

    // 保存密码
    saveStudentPassword() {
      let data = {
        userIdList: this.students.map((x) => x.studentId),
        passwordType: this.form.passwordType,
      };

      if (this.form.passwordType === TYPE_NEW_PASSWORD) {
        data.password = rsaEncryption(this.form.password);
      }

      saveStudentPassword(data)
        .then(() => {
          this.$message.success(`修改密码成功`);
          this.$emit('success');
        })
        .catch(() => (this.loading = false));
    },

    // 批量重置密码
    batchResetStudentPassword() {
      let data = {
        userIdList: this.students.map((x) => x.studentId),
        passwordType: this.form.passwordType,
      };

      if (this.form.passwordType === TYPE_NEW_PASSWORD) {
        data.password = rsaEncryption(this.form.password);
      }

      saveStudentPassword(data)
        .then(() => {
          this.handleSuccess();
        })
        .catch(() => (this.loading = false));
    },

    // 重置全部学生密码
    resetStudentPassword() {
      this.$msgbox
        .confirm('你确定重置全部学生密码？', '提示', { type: 'warning' })
        .then(() => {
          this.isLoading = true;

          let data = {
            userIdList: this.students.map((x) => x.studentId),
            passwordType: this.form.passwordType,
          };

          if (this.form.passwordType === TYPE_NEW_PASSWORD) {
            data.password = rsaEncryption(this.form.password);
          }

          saveStudentPassword(data)
            .then(() => {
              this.handleSuccess();
            })
            .catch(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    handleSuccess() {
      if (this.form.passwordType === TYPE_NEW_PASSWORD) {
        this.$message.success(`重置密码成功`);
      } else {
        this.$message.success(`重置密码成功`);
      }

      this.$emit('success');
    },
  },

  watch: {
    visible: {
      handler: function (val) {
        Object.assign(this.$data, this.$options.data()); // reset
        this.loading = false;
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  display: flex;
  align-items: center;
  padding: 0 17px;
  gap: 13px;
  height: 48px;
  background-color: #fffbf4;
  position: absolute;
  width: 100%;
  left: 0;
  top: 66px;
  color: #303333;
  line-height: normal;
}

.nameBox {
  background: #f8f8f8;
  @apply pl-15px pr-15px py-8px flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
  max-height: 222px;
  overflow: auto;

  span {
    @apply text-F1;
    line-height: 22px;
  }
}

.defaultTips {
  background-color: #f8f8f8;
  padding: 8px 12px;
  font-size: 12px;
  color: #7c8083;
  line-height: normal;
  margin-top: 8px;
}

.password-dialog {
  .tip {
    @apply text-12;
    line-height: 1.5;
    margin-top: 5px;
    color: #b9b9b9;
  }

  // 外层轨道
  ::-webkit-scrollbar-track {
    background-color: #f8f8f8;
  }

  ::v-deep .el-dialog__body {
    padding-top: 64px;
  }
}
</style>
