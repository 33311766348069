/**
 * @desc 基础数据 学生
 */
import { getStudentList, batchDeleteStudent, getClassList, getGradeList } from '@/api/basic';
import { Table, TableColumn, Input, Button, Select, Option, Checkbox } from 'element-ui';

import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import studentDialog from './components/studentDialog';
import passwordDialog from './components/passwordDialog';
import batchDeleteDialog from './components/batchDeleteDialog.vue';
import ETable from '@/components/table/index.vue';
import EButton from '@/components/button/index.vue';
import CascaderHeader from '@components/table/components/cascaderHeader.vue';

import * as consts from './constance';
import OperationBtns from '@components/table/components/operationBtns.vue';

export default {
  name: 'basic-student',

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    basicHeader,
    searchInput,
    qlPagination,
    studentDialog,
    passwordDialog,
    batchDeleteDialog,
    ETable,
    EButton,
  },

  data() {
    return {
      studentStatus: [
        {
          label: '在校学生',
          value: 1,
        },
        {
          label: '已毕业学生',
          value: 2,
        },
      ],

      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        gradeId: '',
        classId: '',
        keyword: '',
      },
      multipleSelection: [],
      selectAll: false,

      studentDialogData: {
        visible: false,
        optionType: consts.DIALOG_TYPE_ADD,
        data: {},
      },
      passwordDialogData: {
        visible: false,
        studentId: '',
        optionType: consts.PASSWORD_RESET_ONE,
        students: [],
      },
      batchDeleteDialogData: {
        visible: false,
      },

      gradeClassList: [],

      selectedRows: [],
      filterOptions: {
        status: 1,
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },
    };
  },

  methods: {
    async getTableData() {
      const res = await getStudentList({
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pageSize,

        ...this.filterOptions,
      });

      if (res.status === 0) {
        this.table.list = res.result.data;
        this.pagination.total = +res.result.recordCount;
      }
    },

    async getAllTableData() {
      const res = await getStudentList({
        pageSize: this.pagination.total,
        pageIndex: 1,
        ...this.filterOptions,
      });

      if (res.status === 0) {
        this.selectedRows = res.result.data;
      }
    },

    pageChange() {
      this.getTableData();
    },

    filterChangeHandler(filterObj) {
      this.filterOptions = {
        ...this.filterOptions,
        [Object.keys(filterObj)[0]]:
          Object.values(filterObj)[0].length > 0 ? Object.values(filterObj)[0].join(',') : undefined,
      };
    },

    // handle 新增
    handleAdd() {
      this.studentDialogData.optionType = consts.DIALOG_TYPE_ADD;
      this.studentDialogData.data = {};
      this.studentDialogData.visible = true;
    },

    // handle 编辑
    handleEdit(val) {
      this.studentDialogData.optionType = consts.DIALOG_TYPE_EDIT;
      this.studentDialogData.data = val;
      this.studentDialogData.visible = true;
    },

    // 修改密码/批量重置密码
    handleChangePassword(val) {
      this.passwordDialogData.optionType = val ? consts.PASSWORD_RESET_ONE : consts.PASSWORD_RESET_BATCH;
      this.passwordDialogData.students = val ? [val] : this.selectedRows;
      this.passwordDialogData.visible = true;
    },

    // handle 修改密码成功
    handlePasswordSuccess() {
      this.passwordDialogData.visible = false;
    },

    // handle 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length) {
        this.selectAll = false;
      }
    },

    // handle 全部
    handleSelectAll() {
      getStudentList({
        pageIndex: 1,
        pageSize: this.pagination.total,

        ...this.filterOptions,
      }).then((res) => {
        if (res.status === 0) {
          this.selectedRows = res.result.data;
        }
      });
    },

    // handle 批量重置密码
    handleBatchReset() {
      this.passwordDialogData.optionType =
        this.selectedRows.length === this.pagination.total ? consts.PASSWORD_RESET_ALL : consts.PASSWORD_RESET_BATCH;
      this.passwordDialogData.students = this.selectedRows;
      this.passwordDialogData.visible = true;
    },

    // handle 删除
    handleDelect(val) {
      this.$msgbox
        .confirm(`你确定删除${val.studentName}吗?`, '提示', {
          customClass: 'ql-message-box_notitle',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          batchDeleteStudent({
            userIdList: [val.studentId],
          }).then(() => {
            this.$message({
              type: 'success',
              message: `已删除${val.studentName}!`,
            });
            this.getTableData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'student',
        downloadMethod: 'POST',
        data: {
          userIdList: this.selectedRows.map((item) => item.studentId),
          ...this.filterOptions,
          classIdList: this.filterOptions.classIdList?.split(','),
        },
      });
    },

    // 获取年级班级，级联数据列表
    getGradeClassList() {
      getGradeList({
        status: 0,
      }).then((res) => {
        if (res.status === 0) {
          const gradeList = res.result.map((item) => ({ label: item.gradeName, value: item.gradeId }));

          getClassList({
            gradeIds: gradeList.map((item) => item.value),
            pageIndex: 1,
            pageSize: 99999,
          }).then((res) => {
            if (res.status === 0) {
              const classList = res.result.data.map((item) => ({
                label: item.className,
                value: item.classId,
                gradeId: item.gradeId,
              }));
              let gradeClassList = gradeList.map((grade) => ({
                ...grade,
                children: classList.filter((item) => item.gradeId === grade.value),
              }));

              gradeClassList.forEach((item) => {
                if (!item.children.length) {
                  item.disabled = true;
                  delete item.children;
                }
              });

              this.gradeClassList = gradeClassList;
            }
          });
        }
      });
    },

    handleGradeClass(val) {
      this.filterOptions = {
        ...this.filterOptions,
        classIdList: val.map((item) => item[1]).join(),
      };
    },

    sortChange({ order }) {
      this.filterOptions = {
        ...this.filterOptions,
        classOrder: {
          ascending: 0,
          descending: 1,
        }[order],
      };
    },
  },

  mounted() {
    this.getTableData();
    this.getGradeClassList();
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    notAllowOperate() {
      // return this.userInfo.thirdpartySchoolProjectCode !== '';
      return false;
    },

    columns() {
      let _columns = [
        {
          label: '账号',
          prop: 'studentLoginName',
        },
        {
          label: '学生姓名',
          prop: 'studentName',
        },
        {
          label: '学号',
          prop: 'idNumber',
        },
        {
          label: '性别',
          prop: 'sexName',
        },
        {
          label: '当前学年所在班级',
          prop: 'classNameList',
          columnKey: 'classIdList',
          sortable: 'custom',
          width: 200,
          filters: [],
          headerProps: () => ({
            placeholder: '当前学年所在班级',
            options: this.gradeClassList,
            hasSortIcon: true,
            props: {
              multiple: true,
            },
            onChange: this.handleGradeClass,
          }),
          headerComponent: CascaderHeader,
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '编辑',
                disabled: () => this.notAllowOperate,
                hidden: () => this.filterOptions.status === 2,
                handle: this.handleEdit,
              },
              {
                text: '删除',
                disabled: () => this.notAllowOperate,
                handle: this.handleDelect,
              },
              {
                text: '修改密码',
                disabled: () => this.notAllowOperate,
                hidden: () => this.filterOptions.status === 2,
                handle: this.handleChangePassword,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];

      if (this.filterOptions.status === 2) {
        _columns = _columns.filter((item) => item.label !== '当前学年所在班级');
      }

      return _columns;
    },
  },

  watch: {
    filterOptions: {
      handler() {
        this.pagination.page = 1;
        this.getTableData();
      },
      deep: true,
    },
  },
};
